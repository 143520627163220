/* .tableScreener {
    table-layout: fixed;
} */
.tableScreener th, .tableScreener td {
    min-width: 100px,
}

.tableScreener th {
    cursor: pointer;
    white-space: nowrap;
}


.tableScreener
th:nth-child(2){
    position: sticky;
    left: 0;
    z-index: 50;
}
.tableScreener td:nth-child(2) {
  position: sticky;
  left: 0;
  z-index: 30;
}

.tableScreener td:nth-child(2) {
    background: white;
}
.tableScreener td:nth-child(2):hover{
    background-color: #f1f1f1;
}

.resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}
.resizer:hover,
.resizing {
    border-right: 2px solid blue;
}

.resizable {
    border: 1px solid gray;
    height: 100px;
    width: 100px;
    position: relative;
}