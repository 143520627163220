.companyCard { 
    padding:0 0 1rem 0;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* max-width: 15%; */
    /* cursor:grab; */
    align-items: left;
    
    
}
.companyCard > *{padding: 0.2rem 1rem 0.2rem 1rem;}
.companyCard img {max-width:70px;min-width: 70px; max-height:70px;margin-left: 0px;background-color: white;border-radius: 10px;}

.companyCard-title > div {display: flex;flex-direction: column;border-right: 1px solid; border-color: white;margin: 1rem;padding: 0 1rem;}
.companyCard-title > div>div:hover{color: aqua;}
.companyCard-title {display: flex;color:white;padding: 1rem; margin-bottom: 1rem;background-color: black;flex-wrap: wrap;border-radius: 15px 15px 0px 0px;}
.companyCard-title > * {margin-right: 1rem;}
.companyCard-title a {text-decoration: none;color: white;}
/* .companyCard:hover{box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

} */