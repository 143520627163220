.loader {
    width: 100%;
    height: 100%;
    /* background: var(--bgWoodSmoke); */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .scanner {
    width: 60px;
    height: 60px;
    /* border: 5px solid white;
    border-radius: 50%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: white; */
    font-size: 2rem; /* Size of the search icon */
    animation: scan 2s infinite linear;
  }
  
  @keyframes scan {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }



/* @keyframes scan {
    0%, 100% {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1) rotate(0deg);
    }
    25% {
        top: 30%;
        left: 70%;
        transform: translate(-50%, -50%) rotate(90deg) scale(1);
    }
    50% {
        top: 70%;
        left: 30%;
        transform: translate(-50%, -50%) rotate(180deg);
    }
    75% {
        top: 30%;
        left: 30%;
        transform: translate(-50%, -50%) rotate(270deg) scale(2.5);
    }
} */
