
.menuButton {
    min-width: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}

.menuHeader {
    display: flex;
    gap: 0.5rem;
    padding: 5px 0;
    align-items: center;
    /* border-bottom: 1px solid #ddd; */
}

.menuHeaderButton {
    display: flex;
    text-transform: none !important;
    color: black;
    text-align: left !important;
    align-items: center !important;
    justify-content: flex-start !important;
    flex:1
}

.subMenuContainer {
    display: flex;
    gap: 0.5rem;
    margin-left: 1rem;
    align-items: center;
}

.subMenuButton {
    min-width: 0 !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    text-align: left !important;
    text-transform: none !important;
    flex:1 !important;
}

.subMenuText {
    color: black;
}

.activeMenuText {
    color: #1976d2;
    font-weight: bolder !important;
}

.searchBar {
    display: flex;
    padding: 0.5rem 0;
    gap: 0.25rem;
    width: 100%;
    box-sizing: border-box;
}

.searchInput {
    flex: 1;
    padding: 0.5rem;
}

.sidebarPaper {
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 200px;
    border-radius: 0 !important;
    left: 0;
    background: #fafafa !important;
    /* background: white !important; */
    /* border-right: 3px solid #F8F8F8; */
    z-index: 1000;
    top: 0;
    padding: 0;
    overflow-y: auto;
    box-sizing: border-box;
}


.menuActiveBorder {
    border-left: 5px solid #1976d2;
    box-sizing: border-box;
    border-radius: 0;
    background: #fafafa;
}

.smallIcon {
    font-size: small;
    color: gray;
}
