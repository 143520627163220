.doodle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: crosshair;
    touch-action: none;
  }
  
  .doodle-line {
    position: absolute;
    background-color: black;
    height: 1px;
    transform-origin: 0 100%;
  }

  