.container{  
    padding: 1rem ;
    width:100%;
    box-sizing: border-box;
    background: rgb(255,255,255);
    background: linear-gradient(175deg, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 65%,rgba(187,193,204,1) 100%);

}

.button{
    border:none;
    padding:0.5rem;
    border-radius:25px;
    font-size: 1rem;
    cursor: pointer;
    height: 3rem;
    border: 2px solid var(--bgColor);
}

.button:hover{
    background-color: var(--bgColor);
    color: white;
}

.cardsContainer {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    padding: 2rem 0rem 0rem 0rem;
    box-sizing: border-box;
    margin: 0;
    gap: 1rem;
    justify-content: space-around;
  }