.profileModal {
    display:flex;
    flex-direction:column;
    padding: 1rem;
    min-width: 200px;
    /* border-radius: 25px; */
    /* padding: 0.5rem; */
}


.profileModal > button {
    cursor: pointer;
    padding: 1rem;
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 1rem;
    font-weight: 500;
    background-color: white;
    color: black;
    text-align: left;
  }
  
  .profileModal > button:hover{
  background-color:  var(--bgColor);
  color: white;
  font-weight: bold;
  }
  