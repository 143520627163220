:root {
  --bgColor: rgb(10, 22, 34);
  --bgWoodSmoke:rgb(19 22 27);
  --bgWoodSmokeLight:rgb(20, 20, 21);
  --tabMainBorder: rgb(10, 22, 34);
  --boxShadowGeneral: rgba(0, 0, 0, 0.25) 0px 5px 5px, rgba(0, 0, 0, 0.22) 0px 10px 10px; 
  --boxShadowTop: rgba(0, 0, 0, 0.25) 0px -5px 5px, rgba(233, 233, 233, 0.22) 0px -10px 10px;
  --bgButtonActive: #e8e8e8;
  --bgLightGray:#F4F5F5;
  --colorSuccess: rgb(237, 247, 237);
  --colorWarning:rgb(255, 244, 229);
  --colorInformation:rgb(229, 246, 253);
  --lightBlue: #00c3ff;
  --selectBlue:#1976d2;
  --backgroundLinearLight:linear-gradient(0deg, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 1) 65%, rgb(240, 240, 240) 100%);
  --backgroundLinear:linear-gradient(180deg, rgba(255, 255, 255, 1) 5%, rgba(255, 255, 255, 1) 65%, rgb(237, 237, 237) 100%);
  --colorButtonActive: rgb(10, 22, 34);
  --tableHover: rgba(0, 0, 0, 0.05);
  --fWbold:600;
  --light-bg: #ffffff;
  --dark-bg: #2c2c2c;
  --light-text: #000000;
  --dark-text: #ffffff;
}

/* green shades */
/* 
#D4EED1
#A8D5BA
#6BBF8F
#3F8F66
#1D5C3A */


html {box-sizing: border-box;color: black;scroll-behavior: smooth;margin: 0;padding: 0;}

body {
  max-width: 100vw;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:'Roboto',"SF Pro Display","SF Pro Icons",-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color:white;
  background:var(--bgWoodSmoke) */
  /* user-select: none;    */
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* Scrollbar */

::-webkit-scrollbar {
  width: 7px; 
  height: 7px;
  border-radius: 10px;
  cursor: grab;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
background-color: #f1f1f1; 
border-radius: 10px;
height:7px
}


::-webkit-scrollbar-thumb {
background-color: #929292; 
border-radius: 10px;
height: 5px
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
background-color:  var(--lightBlue); 
}


::-webkit-scrollbar-track {
  background-color: rgb(213, 213, 213);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  background-color: var(--lightBlue); 
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 2px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 6px;
  width: 12px;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");

}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 6px;
  width: 12px;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
}


