/* EconSingleSeriesUI.module.css */

.tableContainer{
    /* margin: 1rem; */
    box-sizing: border-box;
}

.table {
    width: 100%;
    max-width: 100%;
    
}

.table th {
    min-width: 60%;
    max-width: 60%;
}


.table td {
    font-weight: bold;
    font-size: 1.2rem;
}

.table th:first-child,td:first-child{
    width:60%
}
.table td:nth-child(-n2) {
    font-size: 1.5rem; /* Add the appropriate unit (e.g., em, px, rem) */
}

.table > tbody > tr {
    background-color: rgba(0,0,0,0.02)
}

.table th{
    position: static;
}