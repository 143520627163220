.container {
    width: 15%;
    height:100%;
    overflow:visible;
    user-select: none;
}

.childContainer{
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .container:hover{
  overflow: visible;
} */

.childContainer:hover{
  overflow: auto;
}

.topic{
    border-bottom: 1px solid #ddd;
    width: 100%;
    padding: 1rem 1rem 1rem 0;
    box-sizing: border-box;
    position: sticky;
    z-index: 100;
    background: white;
    top: 0;
}

.container .subTopic{
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  
}

.container .subTopic:hover {
  background:#ddd;
  cursor: pointer;
}

.container .subTopic > h3{
  padding: 4px;
  font-size: 1.2rem;
  margin-left: 0;
  font-weight: bold;
}


.container > h2 {margin: 0;margin-left: 1rem;}
.container > h3 {margin: 0;margin-left: 1rem;}
.container > h4 {margin: 0;margin-left: 1rem;}
.chapterContent {
  height:calc(100% - 120px);
  overflow-y: auto;
}

.chapterContent  ul {
    margin: 0;
    margin-right: 10;
    list-style: none;
    padding: 0; /* Add this line to reset padding as well */
}

.chapterContent li{
   padding: 5px;
   padding-left: 1rem;
   border-bottom: 1px solid #ddd;

}

.chapterContent li:hover{
    background-color: #ddd;
 }

