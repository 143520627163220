.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
  gap: 2rem;
}

.titleSection {
  flex: 1;
}

.title {
  font-weight: 500;
  color: #2C3E50;
  margin: 0;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #718096;
  font-size: 1rem;
  margin: 0;
}

.searchSection {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.searchBar {
  display: flex;
  align-items: center;
  background: #F8FAFC;
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
  width: 300px;
  border: 1px solid #E2E8F0;
}

.searchIcon {
  color: #94A3B8;
  margin-right: 0.5rem;
}

.searchInput {
  border: none;
  background: none;
  font-size: 0.875rem;
  color: #2D3748;
  width: 100%;
  outline: none;
}

.searchInput::placeholder {
  color: #94A3B8;
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  background: #F8FAFC;
  border: none;
  border-radius: 8px;
  color: #2C3E50;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  border: 1px solid #E2E8F0;
}

.filterButton:hover {
  background: #EDF2F7;
}

.filterButton.hasFilters {
  background: #EDF2F7;
  border: 1px solid #CBD5E1;
  color: #1A73E8;
}

.filterBadge {
  position: absolute;
  top: -6px;
  right: -6px;
  width: 16px;
  height: 16px;
  background: #1A73E8;
  color: white;
  border-radius: 50%;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: stretch;
  }

  .searchSection {
    width: 100%;
  }

  .searchBar {
    flex: 1;
    width: auto;
  }
} 