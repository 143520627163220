.container {
    background: #FFFFFF;
    min-height: 100vh;
    padding: 0.5rem;
}

.header {
    margin-bottom: 2rem;
}

.titleSection {
    margin-bottom: 1.5rem;
}

.title {
    font-size: 2rem;
    font-weight: 500;
    color: #2C3E50;
    margin: 0;
    margin-bottom: 0.5rem;
}

.subtitle {
    color: #718096;
    font-size: 1rem;
    margin: 0;
}

/* Summary Section */
.summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: 1rem;
    margin: 1rem 0;
}

.summaryCard {
    background: #FFFFFF;
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.summaryCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.summaryIcon {
    color: #6B7280;
    font-size: 1.5rem !important;
}

.summaryInfo {
    flex: 1;
}

.summaryNumber {
    display: block;
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    margin-bottom: 0.25rem;
}

.summaryLabel {
    color: #6B7280;
    font-size: 0.75rem;
}

.pulseGridCards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.pulseCard {
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #E5E7EB;
    /* border: 1px solid #E5E7EB; */
    padding: 0.2rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    /* padding-bottom: 80px; Add space for action buttons */
}

.pulseCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;
}

.headerLeft {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.headerRight {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.dateText {
    font-size: 0.7rem;
    color: #6B7280;
}

.clientsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 0.35rem;
    margin-bottom: 0.35rem;
}

.clientChipVertical {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    padding: 0.25rem 0.35rem;
    background: #F3F4F6;
    border-radius: 4px;
    font-size: 0.7rem;
    color: #374151;
    transition: background-color 0.2s ease;
}

.clientChipVertical:hover {
    background: #E5E7EB;
}

.clientInfo {
    display: flex;
    flex-direction: column;
    min-width: 0;
    gap: 0.1rem;
}

.clientName {
    font-size: 0.7rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.portfolioType {
    font-size: 0.65rem;
    color: #6B7280;
}

.impact {
    font-size: 0.7rem;
    font-weight: 500;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    white-space: nowrap;
}

.urgent {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-size: 0.7rem;
    font-weight: 500;
    color: #DC2626;
    background: #FEE2E2;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    white-space: nowrap;
}

.urgent svg {
    font-size: 0.9rem !important;
}

.moreClientsButton {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    padding: 0.25rem 0.5rem;
    background: #EFF6FF;
    border: none;
    border-radius: 4px;
    font-size: 0.7rem;
    color: #1D4ED8;
    cursor: pointer;
    width: fit-content;
    transition: background-color 0.2s ease;
}

.moreClientsButton:hover {
    background: #DBEAFE;
}

.typeWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.icon {
    color: #6B7280;
}

.type {
    font-size: 0.875rem;
    color: #4B5563;
    font-weight: 500;
}

.timestamp {
    font-size: 0.75rem;
    color: #6B7280;
}

.cardTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
    margin: 0 0 0.5rem 0;
}

.cardDetails {
    font-size: 0.875rem;
    color: #4B5563;
    margin: 0 0 1rem 0;
    line-height: 1.5;
}

.impactWrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
}

.highImpact {
    background: #FEE2E2;
    color: #991B1B;
}

.mediumImpact {
    background: #FEF3C7;
    color: #92400E;
}

.lowImpact {
    background: #ECFDF5;
    color: #065F46;
}

.sectionTitle {
    font-size: 0.875rem;
    font-weight: 600;
    color: #374151;
    margin: 0 0 0.75rem 0;
}

.clientsList {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.clientItem {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.showMoreButton {
    background: none;
    border: none;
    color: #3B82F6;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.5rem 0;
    cursor: pointer;
    width: 100%;
    text-align: center;
}

.showMoreButton:hover {
    color: #2563EB;
}

.actionSection {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background: white;
    border-top: 1px solid #f0f0f0;
}

.actionSequence {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.actionPreview {
    background: #F9FAFB;
    border-radius: 8px;
    padding: 0.75rem;
}

.actionPreviewContent {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.actionStatus {
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
}

.actionStatus[data-status="Completed"] {
    background: #ECFDF5;
    color: #065F46;
}

.actionStatus[data-status="In Progress"] {
    background: #EFF6FF;
    color: #1D4ED8;
}

.actionStatus[data-status="Pending"] {
    background: #F3F4F6;
    color: #374151;
}

.actionDescription {
    font-size: 0.875rem;
    color: #374151;
    flex: 1;
}

.moreActionsButton {
    width: 100% !important;
    justify-content: flex-start !important;
    text-transform: none !important;
    color: #4B5563 !important;
    background: #F9FAFB !important;
    border: 1px solid #E5E7EB !important;
    padding: 0.75rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    border-radius: 8px !important;
    transition: all 0.2s ease-in-out !important;
}

.moreActionsButton:hover {
    background: #F3F4F6 !important;
    border-color: #D1D5DB !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.actionPopover {
    padding: 1rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    min-width: 300px;
}

.filterContent {
    padding: 1.5rem;
}

.filterSection {
    margin-bottom: 1.5rem;
}

.filterSection:first-child {
    padding-top: 1rem;
}

.filterSection h3 {
    font-size: 0.875rem;
    color: #374151;
    margin-bottom: 0.75rem;
    font-weight: 600;
}

.filterChips {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
}

.filterChip {
    border-radius: 16px !important;
    font-size: 0.75rem !important;
    height: 28px !important;
    cursor: pointer !important;
    transition: all 0.2s ease-in-out !important;
}

.filterChip:hover {
    background-color: #F3F4F6 !important;
}

.filterChip[class*="MuiChip-colorPrimary"] {
    background-color: #E5EDFF !important;
    color: #1E40AF !important;
}

.filterChip[class*="MuiChip-colorPrimary"]:hover {
    background-color: #D1E0FF !important;
}

.customDateRange {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.dateInput {
    flex: 1;
}

.dateSeparator {
    color: #6B7280;
    font-size: 0.875rem;
}

.actionFilters {
    margin-bottom: 1rem;
    padding: 1rem;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #f0f0f0;
}

.actionFilterTitle {
    font-size: 0.875rem !important;
    color: #666;
    margin-bottom: 0.5rem !important;
}

.actionFilterContent {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.actionButtons {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
}

.actionButton {
    flex: 1;
    min-width: 0;
    white-space: nowrap;
}

.bulkActions {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.bulkActionButton {
    font-size: 0.75rem !important;
    padding: 4px 12px !important;
    min-width: unset !important;
    text-transform: none !important;
    border-radius: 16px !important;
    height: 28px !important;
    line-height: 1.2 !important;
    background-color: #2E7D32 !important;
    color: white !important;
}

.bulkActionButton:hover {
    background-color: #1B5E20 !important;
}

.actionDropdown {
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 0.5rem;
}

.actionDropdownHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #E5E7EB;
}

.actionDropdownHeader h4 {
    font-size: 0.875rem;
    font-weight: 600;
    color: #374151;
    margin: 0;
}

.closeButton {
    min-width: 32px !important;
    width: 32px !important;
    height: 32px !important;
    padding: 4px !important;
    color: #6B7280 !important;
}

.actionItem {
    padding: 0.75rem;
    border-radius: 6px;
    background: #F9FAFB;
    margin-bottom: 0.5rem;
    border: 1px solid #E5E7EB;
}

.actionItem:last-child {
    margin-bottom: 0;
}

.actionHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.actionStep {
    font-size: 0.75rem;
    font-weight: 500;
    color: #6B7280;
}

.actionStatus {
    font-size: 0.75rem;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
}

.actionStatus[data-status="Completed"] {
    background: #ECFDF5;
    color: #065F46;
}

.actionStatus[data-status="In Progress"] {
    background: #EFF6FF;
    color: #1D4ED8;
}

.actionStatus[data-status="Pending"] {
    background: #F3F4F6;
    color: #374151;
}

.actionDescription {
    font-size: 0.875rem;
    color: #374151;
    margin: 0.5rem 0;
    line-height: 1.5;
}

.actionButton:hover {
    transform: translateY(-1px) !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
}

.confirmationSection {
    margin-top: 0.75rem;
    padding-top: 0.75rem;
    border-top: 1px solid #E5E7EB;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.cancelButton {
    font-size: 0.75rem !important;
    padding: 4px 16px !important;
    border-radius: 16px !important;
    height: 32px !important;
    text-transform: none !important;
    color: #4B5563 !important;
    border: 1px solid #E5E7EB !important;
}

.confirmButton {
    font-size: 0.75rem !important;
    padding: 4px 16px !important;
    border-radius: 16px !important;
    height: 32px !important;
    text-transform: none !important;
    background-color: #2E7D32 !important;
    color: white !important;
}

.confirmButton:hover {
    background-color: #1B5E20 !important;
}

@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .actionFilters {
        margin: 1rem 0;
        padding: 1rem;
    }

    .filterContent {
        padding: 1rem;
    }

    .pulseGridCards {
        grid-template-columns: 1fr;
    }

    .customDateRange {
        flex-direction: column;
        gap: 0.5rem;
    }

    .dateSeparator {
        text-align: center;
    }
}

.marketFilters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.impactFilters {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
}

.filterButton {
    text-transform: none !important;
    font-weight: 500 !important;
    border-radius: 16px !important;
    padding: 4px 16px !important;
    /* height: 32px !important; */
    font-size: 0.875rem !important;
    border: 1px solid #E2E8F0 !important;
}

.highImpact {
    color: #d32f2f !important;
    border-color: #d32f2f !important;
}
.highImpactSelected {
    background-color: #d32f2f !important;
    color: white !important;
}
.mediumImpactSelected {
    background-color: #ed6c02 !important;
    color: white !important;
}
.lowImpactSelected {
    background-color: #2e7d32 !important;
    color: white !important;
}

.highImpact:hover {
    background-color: rgba(211, 47, 47, 0.04) !important;
}

.mediumImpact {
    color: #ed6c02 !important;
    border-color: #ed6c02 !important;
}

.mediumImpact:hover {
    background-color: rgba(237, 108, 2, 0.04) !important;
}

.lowImpact {
    color: #2e7d32 !important;
    border-color: #2e7d32 !important;
}

.lowImpact:hover {
    background-color: rgba(46, 125, 50, 0.04) !important;
}

.countBadge {
    background-color: rgba(0, 0, 0, 0.08) !important;
    font-size: 0.75rem !important;
    height: 20px !important;
    min-width: 20px !important;
    padding: 0 6px !important;
}

.countBadgeSelected {
    background-color: rgba(0, 0, 0, 0.08) !important;
    font-size: 0.75rem !important;
    height: 20px !important;
    color:white !important;
    min-width: 20px !important;
    padding: 0 6px !important;
}

.highBadge {
    background-color: rgba(211, 47, 47, 0.1) !important;
    color: #d32f2f !important;
}

.mediumBadge {
    background-color: rgba(237, 108, 2, 0.1) !important;
    color: #ed6c02 !important;
}

.lowBadge {
    background-color: rgba(46, 125, 50, 0.1) !important;
    color: #2e7d32 !important;
}

.affectedClientsSummary {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: black;
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    padding: 0.5rem;
    background-color: rgba(237, 108, 2, 0.1) !important;
    border-radius: 8px;
    width: max-content;
}

.actionSection {
    margin-top: 0.5rem;
    padding: 0.75rem;
    border-top: 1px solid #E5E7EB;
}

.actionButtons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.actionButton {
    font-size: 0.75rem !important;
    padding: 4px 12px !important;
    min-width: unset !important;
    text-transform: none !important;
    border-radius: 16px !important;
    height: 28px !important;
    line-height: 1.2 !important;
    color: #4B5563 !important;
    border-color: #E5E7EB !important;
}

.actionButton:hover {
    background-color: #F3F4F6 !important;
    border-color: #D1D5DB !important;
}

.actionButton svg {
    font-size: 1rem !important;
    margin-right: 4px !important;
}

.pulseGridRows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
}

.pulseCardOld {
    background: #FFFFFF;
    border-radius: 12px;
    border: 1px solid #E5E7EB;
    padding: 1.5rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-bottom: 80px;
}

.pulseCardRow {
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #E5E7EB;
    padding: 1rem;
    display: flex;
    gap: 1rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.pulseCardRow:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.pulseContentRow {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding:1rem;
    box-sizing: border-box;
}

.pulseActionsRow {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    min-width: 120px;
    /* background: #F8FAFC; */
    padding: 0.5rem;
    border-radius: 8px;
    /* border-left: 1px solid #E5E7EB; */
    /* justify-content: center; */
}

.categoryText {
    font-size: 0.75rem;
    color: #6B7280;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
}

.titleRow {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
    margin-bottom: 0.25rem;
}

.descriptionRow {
    font-size: 0.875rem;
    color: #4B5563;
    margin: 0;
    margin-bottom: 0.5rem;
    line-height: 1.4;
}

.impactSection {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.clientsRow {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex-wrap: wrap;
}

.clientChip {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.75rem;
    background: #F3F4F6;
    border-radius: 1rem;
    font-size: 0.75rem;
    color: #374151;
}

.moreClientsChip {
    background: #EFF6FF;
    color: #1D4ED8;
    cursor: pointer;
}

.moreClientsChip:hover {
    background: #DBEAFE;
}

.actionButtonRow {
    width: 100% !important;
    justify-content: flex-start !important;
    text-transform: none !important;
    font-size: 0.7rem !important;
    padding: 0.35rem 0.75rem !important;
    border-radius: 6px !important;
    display: flex !important;
    align-items: center !important;
    gap: 0.35rem !important;
    transition: all 0.2s ease !important;
    /* border: 1px solid #E5E7EB !important; */
    background: white !important;
    color: #898b93 !important;
    min-height: 32px !important;
    background: none !important;
}

.actionButtonRow:hover {
    background: #F9FAFB !important;
    border-color: #D1D5DB !important;
    color: #374151 !important;
}

@media (max-width: 768px) {
    .pulseCardRow {
        flex-direction: column;
    }

    .pulseActionsRow {
        flex-direction: row;
        flex-wrap: wrap;
        min-width: 0;
    }

    .actionButtonRow {
        flex: 1;
        min-width: 140px;
    }
}

.impactWrapperRow {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin: 0.5rem 0 1rem 0;
}

.clientsRowVertical {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.clientChipVertical {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #F3F4F6;
    border-radius: 8px;
    font-size: 0.75rem;
    color: #374151;
    transition: background-color 0.2s ease;
}

.clientChipVertical:hover {
    background: #E5E7EB;
}

.moreClientsButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #EFF6FF;
    border: none;
    border-radius: 8px;
    font-size: 0.75rem;
    color: #1D4ED8;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.2s ease;
}

.moreClientsButton:hover {
    background: #DBEAFE;
}

.pulseContentRow {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.affectedClientsSection {
    /* background: #F8FAFC; */
    /* border: 1px solid #E2E8F0; */
    border-radius: 8px;
    padding: 1rem 0;
    margin: 0.75rem 0;
}

.affectedClientsHeader {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.75rem;
}

.affectedClientsTitle {
    font-size: 0.875rem;
    font-weight: 600;
    color: #1E293B;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.affectedClientsCount {
    background: #E2E8F0;
    color: #475569;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
}

.clientsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.75rem;
}

.clientCard {
    background: white;
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    padding: 0.75rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    transition: all 0.2s ease;
}

.clientCard:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    border-color: #CBD5E1;
}

.clientAvatar {
    width: 32px !important;
    height: 32px !important;
    background: #E2E8F0 !important;
    color: #475569 !important;
    font-weight: 500 !important;
}

.clientInfo {
    flex: 1;
    min-width: 0;
}

.clientName {
    font-size: 0.875rem;
    font-weight: 500;
    color: #1E293B;
    margin-bottom: 0.25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.portfolioType {
    font-size: 0.75rem;
    color: #64748B;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.showMoreButton {
    width: max-content;
    margin-top: 0.75rem;
    padding: 0.5rem;
    background: #F1F5F9;
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    color: #3B82F6;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.showMoreButton:hover {
    background: #E2E8F0;
    border-color: #CBD5E1;
} 