/* Default styles for table rows */
/* .table-row {
    transition: background-color 0.3s, border 0.3s, transform 0.3s;
  } */

.table > *{
  background: white;
}
.table > thead > tr {
  border-radius: 5px;
}
  
.tablePinFirstCol td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: white;
    border-left: 0 solid black;
  }
  
/* .table th {
    z-index: 1;
    position: sticky;
    top: -1px;
  } */
  
.table th:first-child {
  position: sticky;
  top: 0;
  z-index: 2;
}

.table td {
  /* border: 1px solid #ddd; */
  color : rgb(45, 45, 45)
}

.tableTicker th {
  z-index: 10;
  border: none;
  position: sticky;
  top: -1px;
  background: #f8f8f8;
  color: black;
}

.tableTicker th:first-child {
position: sticky;
top: 0;
z-index: 2;
}

.tableTicker td {
  border: none;
  }


  
  /* Styles for selected table rows */
.selectedRow {
  /* background-color: #a2f6ff; */
  border: 2px solid var(--selectBlue);
  font-weight: 500;
  border-radius: 4px;
}

/* Styles for dragged table rows */
.draggedRow {
  background-color: lightgray;
}


.resizable-table {
  width: 100%;
  border-collapse: collapse;
}

.resizable-table th {
  position: relative;
  padding-right: 16px; /* Width of the resizing handle */
  background: var(--selectBlue);
}

.resizable-table th:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 16px; /* Width of the resizing handle */
  cursor: col-resize;
  background-color: #f4f4f4; /* Background color of the resizing handle */
}

.resizer {
  position: absolute;
  top: 0;
  right: 0;
  width: 5px;
  cursor: col-resize;
  user-select: none;
  }
  .resizer:hover,
  .resizing {
  border-right: 2px solid var(--selectBlue);
  }
  
  .resizable {
  border: 1px solid gray;
  height: 100px;
  width: 100px;
  position: relative;
  }