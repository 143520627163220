/* LifeStages.module.css */

.container {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    background-color: transparent;
  }
  
  .phases {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 90%;
    gap: 10px;
    height: 100%;
  }
  
  .phaseBox {
    opacity: 0; /* Start hidden */
    animation: fadeIn 0.5s ease forwards;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    position: relative;
  }
  
  .phaseTitle {
    position: absolute;
    top: -2.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: #4caf50;
    text-align: center;
  }
  
  .goalsContainer {
    padding: 1rem 0.5rem;
    font-size: 10px;
    width: 80%;
    color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
  }
  
  .goalItem {
    padding: 0.5rem;
    border-radius: 5px;
  }
  
  .goalTitle {
    font-size: 0.9rem;
    font-weight: bold;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  