
.contentListContainer{
    width:15%;
    min-width: 200px;
    border-right: 1px solid #eeeeee;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
    padding: 0.5rem;
    box-sizing: border-box;
}


.button{
    cursor: pointer;
    background: #eeeeee;
    border: none;
    border-radius: 25px;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    text-align: left;
}

.activeButton{
    background: black;
    color: rgb(255, 255, 255);
}

.button:not(.activeButton):hover {
    background-color: #ddd;
}


.contentInfoContainer{
    flex:1;
    overflow-y: auto;
}

.contentHeader {
    padding: 1rem 0;
}

.contentHeader > h1{
    padding: 1rem 2rem;}

.infoTabs{
    margin-left: 2rem;
}

.cardsContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    /* background: linear-gradient(180deg, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 7%, rgb(246, 248, 252) 100%); */
    padding: 0 2rem 2rem 2rem;
}


.card{
    background: white;
    user-select: none;
    min-width: 15%;
    max-width: 20%;
    text-overflow: ellipsis;
    flex:1;
    max-height:300px;
    border-radius: 10px;
    border: 1px solid #ddd;
    padding: 2rem;
    /* box-shadow: var(--boxShadowGeneral); */
/* transform: perspective(1200px) rotateY(40deg) ;  */
}

.card:hover{
    box-shadow: var(--boxShadowGeneral);
}

