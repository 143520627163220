.financial-calculator {
    border: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .display {
    border: 1px solid #eee;
    padding: 10px;
    margin-bottom: 10px;
    text-align: right;
    background-color: #f9f9f9;
  }
  
  .keypad {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 10px;
  }
  
.financial-calculator button {
    padding: 0px !important;
    min-width: 0 !important;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  
  .financial-calculator button:hover {
    background-color: #b3b2b2;
  }
  

 .numbers {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

 }

 .commonOperations {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;

 }

 .submit {
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 10px;
 }