.table {
  border-collapse: collapse;
  width: 100%;
  /* overflow-x: auto; */
}


.table > thead > tr > th:first-child {
  max-width: 10%;
}

.table > tbody > tr > td:first-child {
  position: sticky;
  top: 0px;
  left: -1px; /* Set left to 0 for both columns */
  background: white;
  z-index: 20;
}


.table th {
  z-index: 20;
  /* border-top: 1px solid #ddd; */
  position: sticky;
  top: 0px;
  background:#F8F8F8;
}

.table > thead > tr > th:first-child {
  position: sticky;
  top: 0px;
  left: -1px;
  z-index: 40;
}


.table tr:hover > td:first-child {
  background: #f7f7f7;
}



