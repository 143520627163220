.container {
  padding: 0.5rem;
  max-width: 100%;
  background: #FFFFFF;
  min-height: 100vh;
}

.header {
  margin-bottom: 2rem;
}

.titleSection {
  margin-bottom: 1.5rem;
}

.title {
  font-size: 2rem;
  font-weight: 500;
  color: #2C3E50;
  margin: 0;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #718096;
  font-size: 1rem;
  margin: 0;
}

.searchSection {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
}

.searchBar {
  display: flex;
  align-items: center;
  background: #F8FAFC;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  flex: 1;
  max-width: 300px;
  border: 1px solid #E2E8F0;
}

.searchIcon {
  color: #94A3B8;
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.searchInput {
  border: none;
  background: transparent;
  font-size: 0.875rem;
  color: #475569;
  width: 100%;
  outline: none;
}

.searchInput::placeholder {
  color: #94A3B8;
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  background: #FFFFFF;
  color: #64748B;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.filterButton:hover {
  background: #F8FAFC;
  border-color: #CBD5E1;
}

.filterButton svg {
  font-size: 1.25rem;
}

/* Updated Category Tabs */
.categoryTabs {
  margin-bottom: 1.5rem !important;
  min-height: 32px !important;
  border-bottom: none !important;
}

.categoryTab {
  text-transform: none !important;
  font-weight: 500 !important;
  min-width: auto !important;
  min-height: 32px !important;
  padding: 6px 16px !important;
  margin-right: 8px !important;
  color: #64748B !important;
  font-size: 0.8125rem !important;
  border-radius: 16px !important;
  border: 1px solid #E2E8F0 !important;
  opacity: 0.8;
}

.categoryTab.Mui-selected {
  color: #2563EB !important;
  background-color: #EFF6FF !important;
  border-color: #BFDBFE !important;
  opacity: 1;
}

/* Updated Upload Section */
.uploadSection {
  display: flex;
  align-items: flex-start;
  margin-bottom:1rem;
}

.uploadButton {
  text-transform: none !important;
  font-weight: 500 !important;
  border-radius: 20px !important;
  padding: 6px 16px !important;
  height: 32px !important;
  font-size: 0.8125rem !important;
  border-color: #E5E7EB !important;
  color: #374151 !important;
  background-color: #F9FAFB !important;
}

.uploadButton:hover {
  background-color: #F3F4F6 !important;
  border-color: #D1D5DB !important;
}

/* Updated Scenario Cards */
.scenariosGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 0.5rem 0;
}

.scenarioCard {
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  padding: 1.25rem;
  transition: all 0.2s ease;
  min-width: 300px;
}

.scenarioCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-color: #D1D5DB;
}

.scenarioCardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.scenarioTitle {
  font-size: 0.9375rem !important;
  font-weight: 500 !important;
  color: #4B5563 !important;
  margin: 0 !important;
  line-height: 1.4 !important;
}

.categoryLabel {
  font-size: 0.75rem !important;
  color: #6B7280 !important;
  margin-top: 0.25rem !important;
  display: block !important;
}

.scenarioActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
}

.relatedClientsButton {
  text-transform: none !important;
  font-weight: 500 !important;
  border-radius: 16px !important;
  padding: 4px 12px !important;
  height: 28px !important;
  font-size: 0.75rem !important;
  border-color: #E5E7EB !important;
  color: #6B7280 !important;
}

.relatedClientsButton:hover {
  background-color: #F9FAFB !important;
  border-color: #D1D5DB !important;
}

.modelIndicator {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.75rem;
  height: 28px;
  border: 1px solid #E5E7EB;
}

.modelIndicator.custom {
  background-color: #EFF6FF;
  border-color: #BFDBFE;
  color: #2563EB;
}

.modelIndicator.default {
  background-color: #F3F4F6;
  border-color: #E5E7EB;
  color: #6B7280;
}

.modelText {
  font-size: 0.75rem !important;
  font-weight: 500 !important;
}

.customModelIcon {
  font-size: 1rem !important;
  color: #2563EB !important;
}

.defaultModelIcon {
  font-size: 1rem !important;
  color: #6B7280 !important;
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .scenariosGrid {
    grid-template-columns: 1fr;
  }

  .categoryTab {
    padding: 4px 12px !important;
  }
} 