.container {
  padding: 0.5rem;
  max-width: 100%;
  background: #FFFFFF;
}

.header {
  margin-bottom: 2rem;
}

.titleSection {
  margin-bottom: 1.5rem;
}

.title {
  font-size: 2rem;
  font-weight: 500;
  color: #2C3E50;
  margin: 0;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #718096;
  font-size: 1rem;
  margin: 0;
}

.searchSection {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 2rem;
}

.searchBar {
  display: flex;
  align-items: center;
  background: #F8FAFC;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  flex: 1;
  max-width: 300px;
  border: 1px solid #E2E8F0;
}

.searchIcon {
  color: #94A3B8;
  margin-right: 0.5rem;
  font-size: 1.25rem;
}

.searchInput {
  border: none;
  background: transparent;
  font-size: 0.875rem;
  color: #475569;
  width: 100%;
  outline: none;
}

.searchInput::placeholder {
  color: #94A3B8;
}

.filterButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  background: #FFFFFF;
  color: #64748B;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
}

.filterButton:hover {
  background: #F8FAFC;
  border-color: #CBD5E1;
}

.filterButton svg {
  font-size: 1.25rem;
}

/* Summary Cards */
.summaryCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.summaryCard {
  padding: 1.5rem !important;
  border-radius: 12px !important;
  display: flex !important;
  align-items: center;
  gap: 1rem;
  border: 1px solid #f0f2f5 !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.summaryCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08) !important;
}

.summaryIcon {
  font-size: 2rem !important;
  color: #64748b;
}

.summaryContent {
  flex: 1;
}

/* Table Styles */
.tableContainer {
  border-radius: 12px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04) !important;
  border: 1px solid #f0f2f5;
}

.tableRow {
  transition: background-color 0.2s ease;
}

.tableRow:hover {
  background-color: #f8fafc !important;
}

/* Portfolio Type Chips */
.conservative {
  background-color: #e0f2fe !important;
  color: #0369a1 !important;
}

.moderate {
  background-color: #fef3c7 !important;
  color: #b45309 !important;
}

.aggressive {
  background-color: #fee2e2 !important;
  color: #b91c1c !important;
}

/* Status Chips */
.active {
  background-color: #dcfce7 !important;
  color: #15803d !important;
}

.inactive {
  background-color: #f1f5f9 !important;
  color: #475569 !important;
}

/* Growth Indicators */
.growthCell {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.trendingUp {
  color: #15803d;
}

.trendingDown {
  color: #b91c1c;
}

/* Risk Score */
.riskScore {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.riskProgress {
  flex: 1;
  height: 6px !important;
  border-radius: 3px !important;
  background-color: #f1f5f9 !important;
}

.riskProgress > .MuiLinearProgress-bar {
  background-color: #64748b !important;
}

/* Goal Chips */
.goalChips {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.goalChip {
  background-color: #f1f5f9 !important;
  color: #475569 !important;
  font-size: 0.75rem !important;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .container {
    padding: 1.5rem;
  }

  .summaryCards {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .summaryCards {
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }

  .title {
    font-size: 1.75rem;
  }
}

.metricsContainer {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.metricCard {
  flex: 1;
  min-width: 220px;
  background: #FFFFFF;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  border: 1px solid #E2E8F0;
  transition: all 0.2s ease-in-out;
}

.metricCard:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.metricValue {
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  color: #2D3748 !important;
  margin-bottom: 0.25rem !important;
  letter-spacing: -0.5px !important;
}

.metricTitle {
  font-size: 0.875rem !important;
  color: #4A5568 !important;
  margin-bottom: 0.25rem !important;
}

.metricSubtitle {
  font-size: 0.75rem !important;
  color: #718096 !important;
  display: block;
}

.todoChips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.todoChip {
  margin: 2px 4px;
}

.filterContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2rem 1rem;
}

.filterSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filterSectionTitle {
  font-size: 0.85rem !important;
  font-weight: 600 !important;
  color: #374151 !important;
  margin-bottom: 2px !important;
  letter-spacing: 0.05em !important;
  text-transform: capitalize !important;
}

.filterOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}

.filterChip {
  background-color: #F9FAFB !important;
  color: #374151 !important;
  border: 1px solid #E5E7EB !important;
  border-radius: 4px !important;
  padding: 4px 10px !important;
  font-size: 0.75rem !important;
  font-weight: 500 !important;
  transition: all 0.2s ease !important;
  height: 28px !important;
}

.filterChip:hover {
  background-color: #F3F4F6 !important;
  border-color: #D1D5DB !important;
  transform: translateY(-1px);
}

.filterChip.selected {
  background-color: #EFF6FF !important;
  color: #2563EB !important;
  border-color: #BFDBFE !important;
  box-shadow: 0 1px 2px rgba(37, 99, 235, 0.1);
}

.filterChip.selected:hover {
  background-color: #DBEAFE !important;
  transform: translateY(-1px);
}

/* Range Slider Styles */
.rangeSlider {
  padding: 6px 0;
}

.rangeValues {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  color: #6B7280;
  font-size: 0.75rem;
  font-weight: 500;
}

/* Search Results */
.searchResults {
  margin-top: 12px;
  padding: 12px;
  background-color: #F9FAFB;
  border-radius: 6px;
  border: 1px solid #E5E7EB;
}

.noResults {
  color: #6B7280;
  text-align: center;
  padding: 16px;
  font-size: 0.75rem;
  font-weight: 500;
}

/* Responsive Design */
@media (max-width: 600px) {
  .filterContent {
    gap: 16px;
  }

  .filterSection {
    gap: 6px;
  }

  .filterOptions {
    gap: 4px;
  }

  .filterChip {
    padding: 3px 8px !important;
    font-size: 0.688rem !important;
    height: 24px !important;
  }
} 