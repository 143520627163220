
.container {width:45%;
display:flex;
box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px; 
margin: 1rem;
height: 300px;
}

.infoBoxWrapper {
    display:flex;
    flex-direction:column;
    border-right: 1px solid #ddd;
}