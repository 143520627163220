.tab-container{
    box-sizing: border-box;
    display: flex; 
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: black;
   
}


.tab {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 15%;
    border-right: 1px dashed #ddd;
    overflow-x: hidden;
    box-sizing: border-box;
}
.tabs * {
    white-space: noWrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tab-content {
    box-sizing: border-box;
    flex:1;
    /* max-width: 85%;
    width: 85%; */
    overflow: auto;
    padding: 0 1rem;
}

/* .tab> button:first-child{margin-top: 0.5rem;} */

.button{
    display: flex;
    padding: 0.5rem !important;
    border: none;
    cursor: pointer;
    background: none;
    text-align: left;
    text-transform: none;
    justify-content: flex-start !important;
    line-height: 1 !important;
    color: rgb(67, 67, 67) !important;
    &.activeButton {
        color: black !important;
        background: #F8F8F8;
        font-weight: bold;
    };

}

.button:hover{
    /* font-weight: bold;
    font-size: large; */
    color: black !important;
    background-color: #fafafa;
}

