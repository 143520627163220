.chatsContainer {
    display: flex;
    gap: 1rem;
    flex-direction: column;
  }
  
.chatItem {
cursor: pointer;
display: flex;
gap: 0.5rem;
padding: 0.75rem;
border-radius: 5px;
max-width: 100%;
overflow: hidden;
}

.chatItem:hover{
    background: #f7f7f7;
}

  .chatDetails {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .displayName {
    font-weight: bold;
  }
  
  .lastMessage {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
  }
  