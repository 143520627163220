.filterFieldCell {
    box-sizing: border-box;
    padding: 0.5rem;
    display: flex;
    width: 50%;
    gap: 1rem;
    align-items: center;
    border-bottom: 1px solid #ddd;
  }
  
.filterFieldCellActive {
    box-sizing: border-box;
    padding: 0.5rem;
    display: flex;
    width: 50%;
    gap: 1rem;
    align-items: center;
    border-bottom: 1px solid #ddd;
    background: lightBlue;
  }
