.searchButton{
    width: 100%;
    padding: 0.35rem !important;
    /* height: 2rem; */
    /* background-color: white !important; */
    border: none;
    border-radius: 5px !important;
    outline: none;
    display: flex;
    align-items: center;
    box-shadow: none;
    border: 2px solid transparent;
    background: #fafafa;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  }

.searchButton:hover{
   border: 2px solid  var(--selectBlue);
  }

.searchButton:active{
    border: 2px solid  var(--selectBlue);
   }