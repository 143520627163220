.imageContainer {
    position: relative;
    width: 100%; /* Adjust as needed */
    height: 250px; /* Adjust as needed */
    overflow: hidden;
    box-sizing: border-box;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* backdrop-filter: blur(10px); */
    filter: blur(4px); /* Apply a subtle blur to the image */
    transform: scale(1.05); /* Slightly scale the image to avoid visible edges after blurring */
  }
  
  .textContainer {
    position: absolute;
    bottom:0px;
    left: 40px;
    text-align: left;
    padding: 0px; /* Adjust padding as needed */
    z-index: 20;
    /* border-radius: 25px 0px; */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6); 
   
  }
  
  .text {
    position: relative;
    display: inline-block;
    padding: 10px 20px; /* Adjust to create space between text and the start of the border line */
    background: #ffffff;
    z-index: 1;
    border-radius: 5px;
  }


  