.tabLayout{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 0;
}

.subTabs {
  background: rgb(255, 255, 255);
  position: sticky;
  display:flex;
  flex-direction: row;
  min-width: 0;
  width: 100%;
  gap:0.5rem;
  /* width: 15%; */
  color:rgb(242, 235, 235);
  overflow: hidden;
  flex:1;
  padding:0.5rem 0.25rem
}

.subTab_content{
  height: 100%;
  overflow-y:auto;
  width: 100%;
  flex:1;
}

.subTabs > button{
  font-size: 0.8rem;
    white-space: nowrap; 
    /* text-overflow: ellipsis;  */
    padding: 0.2rem;
    display: flex;
    align-items: center;
    border: none;
    cursor: pointer;
    border-radius: 20px;
    gap: 0.25rem;
    &.activeButton {
        color:rgb(0, 0, 0);
        background: #f8f8f8;
        border: 2px solid var(--selectBlue)
        /* border-radius:5px 5px 0 0; */
        /* border-bottom: 2px solid;
        border-color: rgb(1, 1, 1); */
      };
      
    &.inactiveButton {
    background-color: transparent;
    color: black;
    }
    &:hover{
      background: #f8f8f8;
    }
} 













/* 



.subTabs::-webkit-scrollbar {
  width: 5px; 
  height: 5px;
  border-radius: 20px;
  cursor: grab;
}

.subTabs::-webkit-scrollbar-track {
background-color: #f1f1f1; 
border-radius: 20px;
height: 5px
}

.subTabs::-webkit-scrollbar-thumb {
background-color: #000000; 
border-radius: 20px;
height: 5px
}

.subTabs::-webkit-scrollbar-thumb:hover {
  border-radius: 20px;
background-color: #00c3ff; 
} */