.mainTabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    
    max-width: 100%;
    width: 100%;
    /* background-color: rgb(255, 255, 255); */
}



.tabs{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width:100%;
    
}

.mainTabsContent {
    flex:1;
    max-width: 100%;
    min-width: 100%;
    overflow:auto;
}



.mainTabsButtonWrapper{
    box-sizing: border-box;
    /* background: rgb(255, 255, 255); */
    display:flex;
    flex:1;
    overflow-x:auto;
    align-items: center;
    gap:1rem;
    /* border-bottom: 1px solid #ddd; */
    
}



.mainTabsButtonWrapper>button{
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 1rem;
    padding: 0.25rem;
    border: none;
    cursor: pointer;
    box-sizing: border-box;
    /* font-size: 0.7rem; */
        
    &.actBtnv1 {
        border-radius: 25px;
        border: 2px solid var(--tabMainBorder); 
        background: black;
        color: white;  
          
      }
        
    &.actBtnv2 {
        border-bottom: 2px solid black;
        background: none;
       
      };
    &.actBtnv3 {
        background-color: black;
        color: white;       
      };
    &.inactiveButton {
    background-color: #eeeeee;
    color: black;
    border:none;
    border-radius: 25px;
    
    }
    &.inactiveButton:hover {
        border: 1px solid black;
    }
} 

