.card {
    width: 20%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    border-radius: 25px;
  }

.card:hover{ box-shadow: var(--boxShadowGeneral);}

.cardContent{
    padding: 1rem;
    text-align: center;
}

.cardContent > * {
    margin-bottom: 0.5rem;
}

.cardContent ul{
    text-align: center;
    margin: 0;
    list-style: none;
    padding: 0; /* Reset padding */
}
.cardContent li {
    margin: 0;
    text-align: center;
    border-bottom: 1px solid #eee;
    padding: 0; /* Reset padding */
    padding-bottom: 10px;
    padding-top: 10px;
}

.button {
    margin-top: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    width: 200px;
    border: none;
    border-radius: 25px;
  }

.button:hover {
    background: var(--bgColor);
    color:white
  }

