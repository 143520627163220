.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .explanation {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 10px;
    background-color: #fafafa;
  }
  
  .explanation h2 {
    margin-top: 0;
  }
  
  .inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    align-items: flex-end;
  }

  

  .chartContainer {
    margin-bottom: 40px;
  }
  
  .cashflowChartContainer {
    margin-top: 20px;
    padding: 1rem;
  }
  
  .chartExplanation {
    margin-top: 10px;
    padding: 10px;
    background-color: #fafafa;
    font-size: 14px;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;
    table-layout: fixed;
  }
  
  .table th,
  .table td {
    /* border: 1px solid #dddddd; */
    text-align: left;
    padding: 8px;
  }
  
  
  .tableRow {
    cursor: pointer;
  }
  
  .tableRow:hover {
    background-color: #f1f1f1;
  }
  
  .expandedRow td {
    padding: 0;
    background-color: #fafafa;
  }
  