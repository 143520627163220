/* MessageTape.module.css */

.tickerContainer {
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .tickerWrapper {
    position: relative; /* Added to position pseudo-elements correctly */
    overflow: hidden;
    width: 100%;
    height: 60px; 
    margin: 5px 0;
  }
  
  .ticker {
    display: flex;
    animation-name: tickerAnimation;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    gap:1rem
  }
  
  .tickerContent {
    display: flex;
    flex-shrink: 0;
    gap: 1rem;
  }
  
  .tickerButton {
    border-radius: 20px !important;
    flex-shrink: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    color:gray ! important;
  }

  .tickerButton:hover{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Box shadow on hover */
    color:var(--selectBlue) ! important;
  }
  
  /* Gradient blur effect at the ends */
  .tickerWrapper::before,
  .tickerWrapper::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100px; /* Adjust width for the blur effect */
    height: 100%;
    z-index: 1;
  }
  
  .tickerWrapper::before {
    left: 0;
    background: linear-gradient(to right, white, transparent);
  }
  
  .tickerWrapper::after {
    right: 0;
    background: linear-gradient(to left, white, transparent);
  }
  
  @keyframes tickerAnimation {
    from {
      transform: translateX(0);
    }
    to {
      /* Move the content left by the width of one set of messages */
      transform: translateX(-100%);
    }
  }
  
  /* Pause animation on hover */
  .tickerWrapper:hover .ticker {
    animation-play-state: paused;
  }
  
  