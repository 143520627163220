.elements:hover{
    /* border: 3px solid blue; */
    background: white;
    box-shadow: var(--boxShadowGeneral);
}

.elements {
    border: 3px solid #e5e5e5;
}

.imgUrl:hover{
    box-shadow: var(--boxShadowGeneral);
    border-radius: 5px;
}

.leftSidebar{
    z-index: 1000;
    box-sizing: border-box;
    position: fixed;
    left:0;
    top: 40px;
    width: 50px;
    background: white;
    /* transition: width 0.3s ease-in-out; */
    display: flex;
    flex-direction: column;
    border-right:1px solid #ddd;
    flex-shrink: 0;
    gap:1rem;
    height:calc(100vh - 40px);
    padding: 0.25rem;
    }