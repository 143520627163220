.faqContainer {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .faqItem {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
  
  .faqQuestion {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    align-items: center;
    padding: 1rem 0;
  }
  
  .faqAnswer {
    color: #555;
    padding:1rem 0
  }