.contactListContainer {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
  }
  
  .header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding: 0.25rem 0;
  }
  
  .chattingProfile {
    margin-left: 0.5rem;
    cursor: pointer;
    flex: 1;
    display: flex;
    gap: 0.5rem;
    border-radius: 5px;
    max-width: 100%;
    overflow: hidden;
    align-items: center;
    padding:0.25rem
  }
  
  .displayName {
    font-weight: bold;
  }
  