
.container {
  border-top: 1px solid #ddd;
  box-sizing: border-box;
    min-width: 15%;
    width: 20%;
    
    /* min-height: 80%; */
    /* color: white;
    background-color: rgb(255, 255, 255); */
    display: flex;
    flex-direction: row;
    max-width: 50%;
    height:100%;
    /* position: absolute; */
    background:white;
    position: relative;
  }
  

.content{
  box-sizing: border-box;
    flex: 1 1 0;
    max-width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}
  
  
.resizerH{
cursor:ns-resize;
background-color: #ddd;
width:100%;
height:0.3rem;
}

.resizerV{
cursor:col-resize;
background-color: #ddd;
width:0.3rem;
min-width: 0.3rem;
    }

.resizerVDrag{
  cursor:col-resize;
  background-color: #00c3ff;
  width:0.3rem;
  min-width: 0.3rem;
      }