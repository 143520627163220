
  /*Resizeable*/
  .resizer-right-long {
    width: 5px;
    height: 100%;
    background: transparent;
    position: absolute;
    top:0px;
    right: -5px;
    cursor: e-resize;
  }
  .resizer-left-long {
    width: 5px;
    height: 100%;
    background: transparent;
    position: absolute;
    top:0px;
    left: -5px;
    cursor: e-resize;
  }
  .resizer-left {
    width: 5px;
    height: 20px;
    background: transparent;
    position: absolute;
    left: -5px;
    top:50%;
    transform: translate(-50%, -50%);
    cursor: e-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 50% 0 0 50%;
  }
  .resizer-right {
    width: 5px;
    height: 20px;
    background: transparent;
    position: absolute;
    right: -10px;
    top:50%;
    transform: translate(-50%, -50%);
    cursor: e-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 0% 50% 50% 0%;
    
    
  }
  .resizer-bottom-long {
    width: 100%;
    height: 5px;
    background: transparent;
    position: absolute;
    bottom: -5px;
    left:0;
    cursor:n-resize;
  }
  .resizer-bottom {
    width: 20px;
    height: 5px;
    /* background: transparent; */
    position: absolute;
    bottom: -10px;
    left:50%;
    transform: translate(-50%, -50%);
    cursor: n-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 0 0 50% 50%;
    
  }
  .resizer-top-long {
    width: 100%;
    height: 5px;
    background: transparent;
    position: absolute;
    top: -5px;
    left:0;
    cursor:n-resize;
    
  }
  .resizer-top {
    width: 20px;
    height: 5px;
    /* background: transparent; */
    position: absolute;
    top: -5px;
    left:50%;
    transform: translate(-50%, -50%);
    cursor: n-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 50% 50% 0 0;
  }
  
.resizer-both-bR {
    position: absolute;
    right: -10px;
    height: 10px;
    width: 10px;
    bottom: -10px;
    cursor: nw-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 50%;

  }
  .resizer-both-bL {
    position: absolute;
    left: -10px;
    height: 10px;
    width: 10px;
    bottom: -10px;
    cursor: nw-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 50%;

  }
  
  
  .resizer-both-tR {
    position: absolute;
    right: -10px;
    height: 10px;
    width: 10px;
    top: -10px;
    cursor: nw-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 50%;
  }
  .resizer-both-tL {
    position: absolute;
    left: -10px;
    height: 10px;
    width: 10px;
    top: -10px;
    cursor: nw-resize;
    background-color: white;
    border:1px solid black;
    border-radius: 50%;

  }
  /*NOSELECT*/
  
