/* .react-flow .react-flow__handle-top {
  top: -10px;
}

.react-flow .react-flow__handle-bottom {
  bottom: -10px;
} */

.react-flow .react-flow__edge path,
.react-flow__connectionline path {
  stroke-width: 2;
}

.react-flow .react-flow__handle {
  width: 5px;
  height: 5px;
  border-radius: 2px;
  background-color: #784be8;
}
