.fade-in-left {
    animation: fadeInLeft ease 3s infinite;
    -webkit-animation: fadeInLeft ease 3s;
    -moz-animation: fadeInLeft ease 3s;
    -o-animation: fadeInLeft ease 3s;
    -ms-animation: fadeInLeft ease 3s;
  }
  @keyframes fadeInLeft {
    0% {
      opacity:0;
      transform: translateX(-40px);
    }
    100% {
      opacity:1;
      transform: translateX(0px);
    }
  }
  
  .fade-in-out {
    animation: fadeInOut ease 5s infinite;
    -webkit-animation: fadeInOut ease 5s;
    -moz-animation: fadeInOut ease 5s;
    -o-animation: fadeInOut ease 5s;
    -ms-animation: fadeInOut ease 5s;
    opacity: 0;
  }
  @keyframes fadeInOut {
    0% {
      opacity:0;
      transform: translateX(0px);
    }
    50% {
      opacity:0.8;
      transform: translateX(0px);
    }
    100% {
      opacity:0;
      transform: translateX(0px);
    }
  }
  

  .fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
  }
  @keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }


  .fade-in-simple {
    animation: fadeInSimple ease 2s infinite;
    -webkit-animation: fadeInSimple ease 2s infinite;
    -moz-animation: fadeInSimple ease 2s infinite;
    -o-animation: fadeInSimple ease 2s infinite;
    -ms-animation: fadeInSimple ease 2s infinite;
  }
  @keyframes fadeInSimple {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }

  .fade-out {
    animation: fadeOut ease 1s;
    -webkit-animation: fadeOut ease 1s;
    -moz-animation: fadeOut ease 1s;
    -o-animation: fadeOut ease 1s;
    -ms-animation: fadeOut ease 1s;
  }
  @keyframes fadeOut {
    0% {
      opacity:0;
      transform: translateY(-100%);
    }
    100% {
      opacity:1;
      transform: translateY(0px);
    }
  }

  .flip-horizontal {
    animation: flipHorizontal ease 1s;
    -webkit-animation: flipHorizontal ease 1s;
    -moz-animation: flipHorizontal ease 1s;
    -o-animation: flipHorizontal ease 1s;
    -ms-animation: flipHorizontal ease 1s;
  }
  
  @keyframes flipHorizontal {
    0% {
      transform: rotateY(0deg);
      opacity: 1;
    }
    50% {
      transform: rotateY(90deg);
      opacity: 0.5;
    }
    100% {
      transform: rotateY(180deg);
      opacity: 1;
    }
  }

  .smooth-rotate {
    animation: smoothRotate ease-in-out 1s;
    -webkit-animation: smoothRotate ease-in-out 1s;
    -moz-animation: smoothRotate ease-in-out 1s;
    -o-animation: smoothRotate ease-in-out 1s;
    -ms-animation: smoothRotate ease-in-out 1s;
  }
  
  @keyframes smoothRotate {
    0% {
      transform: rotateY(90deg);
    }
    100% {
      transform: rotateY(180deg);
    }
  }

  .gradient_text {
    font-weight: bold; /* Ensures that the gradient is more visible */
    background-image: linear-gradient(45deg, #34bdf8, #54ec6b , #50ecec); /* Example gradient colors */
    background-clip: text;
    -webkit-background-clip: text; /* For Safari */
    color: transparent; /* Fallback color */
    -webkit-text-fill-color: transparent; /* For Safari and older Chrome */
  }


.gradient_text-dark {
  font-weight: bold; /* Ensures that the gradient is more visible */
  background-image: linear-gradient(45deg, #2a70ea, #1f5a84, #a71369); /* Example gradient colors */
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent; /* Fallback color */
  -webkit-text-fill-color: transparent; /* For Safari and older Chrome */
}

.gradient_text_green_dark {
  font-weight: bold; /* Ensures that the gradient is more visible */
  background-image: linear-gradient(45deg, #18aea9, #16b178, #1bbf68); /* Example gradient colors */
  background-clip: text;
  -webkit-background-clip: text; /* For Safari */
  color: transparent; /* Fallback color */
  -webkit-text-fill-color: transparent; /* For Safari and older Chrome */
}


.flip-card {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #fff;
  color: black;
}

.flip-card-back {
  background-color: rgba(0,0,0,0.10);
  color: white;
  transform: rotateY(180deg);
}

.flip {
  transform: rotateY(180deg);
}


.logoHover{
  background: rgb(10,22,34); /* Fallback color */
  background: linear-gradient(90deg, var(--bgWoodSmoke) 50%, rgb(255, 255, 255) 50%);
  animation: logoHoverAnim 4s infinite;
}

@keyframes logoHoverAnim {
  0%, 100% {
    background: var(--bgWoodSmoke)
  }
  50% {
    background: white;
  }
}
/* 
@keyframes logoHoverAnim {
  0%, 100% {
    background: linear-gradient(56deg,rgb(5, 94, 3) 0%, rgba(15,255,1,1) 98%);
  }
  25% {
    background: linear-gradient(56deg,rgb(5, 94, 3) 0%, rgba(100,100,255,1) 100%); 
  }
  50% {
    background: linear-gradient(56deg,rgb(5, 94, 3) 0%, rgba(228, 221, 10, 0.845) 100%); 
  }
  75% {
    background: linear-gradient(56deg, rgb(5, 94, 3) 0%, rgba(255,100,100,1) 100%); 
  }
} */



.recording{
  color: green;
  animation: recordingAnim 3s linear infinite;
}
.recordingHover{
  color: green;
}
.recordingHover:hover{
  animation: recordingAnim 3s linear infinite;
}

@keyframes recordingAnim {
  0% {
    color: green  !important;
  }
  50% {
    color: red  !important
  }
  100% {
    color: orange  !important;
  }
}


.recording:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  animation: ripple 0.6s linear infinite;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
}



@keyframes typewriter {
  from { width: 0; }
  to { width: 100%; }
}

.typewriter{
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid rgb(1, 1, 1); /* The cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  width: 0; /* The starting width */
  animation: typewriter 4s steps(40, end) 1s 1 normal both; /* Animation */
  /* font-family: 'Courier New', Courier, monospace; */
}


.tickerTape {
  white-space: nowrap;
  /* overflow: hidden; */
  position: relative;
  animation: ticker-move 30s linear infinite;
  animation-delay: -30s;
  transition: transform 1s ease-in-out; /* Smooth transition */
}

/* .tickerTapeContent {
  padding-right: 100%;
} */

@keyframes ticker-move {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.zoom-in {
  animation: zoomIn ease 1s;
  -webkit-animation: zoomIn ease 1s;
  -moz-animation: zoomIn ease 1s;
  -o-animation: zoomIn ease 1s;
  -ms-animation: zoomIn ease 1s;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.75); /* Start from half the size */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Scale to normal size */
  }
}



.fade-in-text {
  animation: fadeInText ease 3s;
  -webkit-animation: fadeInText ease 3s;
  -moz-animation: fadeInText ease 3s;
  -o-animation: fadeInText ease 3s;
  -ms-animation: fadeInText ease 3s;
}
@keyframes fadeInText {
  0% {
    opacity:0;
    /* transform: translateY(100%); */
  }
  100% {
    opacity:1;
    /* transform: translateY(0); */
  }
}


.stock-market-line {
  position: absolute;
  top: 20%;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: 0; /* Ensure it's behind other content */
}

/* path {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  animation: draw 10s linear;
}

@keyframes draw {
  from {
    stroke-dashoffset: 200;
  }
  to {
    stroke-dashoffset: 0%;
  }
}

path.predictor-line {
  stroke-dasharray: 100%;
  stroke-dashoffset: 100%;
  opacity: 1;
  animation: draw 10s linear;
} */


.arrow-icon {
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.3s ease;
}

.arrow-icon {
  opacity: 1;
  transform: translate(-50%, 1rem);
}




.connector {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100px;
}

.flowing-line {
  stroke: rgb(253, 253, 253);
  stroke-width: 2;
  stroke-dasharray: 5;
  animation: dash 2s linear infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: -10;
  }
}


.grid-lines {
  position: absolute;
  top: -100px;
  left: -100px;
  right: -100px;
  bottom: -100px;
  background-image: linear-gradient(#5f5f5f 1px, transparent 1px),
                    linear-gradient(90deg, #868686 1px, transparent 1px);
  background-size: 200px 200px; /* Adjust the size to change the grid spacing */
  pointer-events: none; /* This ensures that the grid does not interfere with other elements */
}

.grid-dots {
  position: absolute;
  top: 0px; /* Offset to hide the outermost dots */
  left: 0px; /* Offset to hide the outermost dots */
  right: 0px; /* Offset to hide the outermost dots */
  bottom: 0px; /* Offset to hide the outermost dots */
  background-image: radial-gradient(#dbdbdb 1px, transparent 1px);
  background-size: 50px 50px; /* Adjust the size to change the dot spacing */
  pointer-events: none; /* This ensures that the grid does not interfere with other elements */
  /* animation: dot-animation 3s infinite; */
}

.grid-dots:hover{
  background-image: radial-gradient(#333236 1px, transparent 1px);
  animation: dot-animation 3s infinite; /* Animation applied */
}

@keyframes dot-animation {
  0%{
      background-image: radial-gradient(#333236 1px, transparent 1px);
      transform: scale(0);
      /* background-size: 20px 20px; */
  }
  33% {
      background-image: radial-gradient(#21ba33 1px, transparent 1px);
      transform: scale(1);
      /* background-size: 22px 22px; */
  }
  66% {
      background-image: radial-gradient(#1c8ebf 1px, transparent 1px);
      transform: scale(0.5);
      /* background-size: 24px 24px; */
  }
  100% {
    transform: scale(0);
  }
}

@keyframes rotateGradient {
  0% {
    background: linear-gradient(330deg, rgba(9, 97, 149, 1) 0%, rgba(0, 0, 0, 1) 100%);
  }
  50% {
    background: linear-gradient(335deg, rgba(9, 97, 149, 1) 0%, rgba(0, 0, 0, 1) 100%);
  }
  100% {
    background: linear-gradient(350deg,  rgba(9, 97, 149, 1) 0%, rgba(0, 0, 0, 1) 100%);
  }
}

.backgroundAnimate {
  background: linear-gradient(45deg,rgb(25, 55, 73) 0%, rgb(18, 104, 28) 100%);
  /* animation: rotateGradient 10s linear infinite; */
}


.fade-background {
  background: rgba(0, 0, 0, 0); /* Initial transparent background */
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
}

.fade-in-animation {
  animation: fadeBackground 6s ease forwards, blurEffect 0.5s ease forwards;
}

@keyframes fadeBackground {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 1);
  }
}

@keyframes blurEffect {
  from {
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  to {
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
  }
}

@keyframes glowPulse {
  0% {
    box-shadow: 0 0 15px 5px rgba(0, 255, 208, 0.8);
    filter: blur(10px);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(2, 170, 176, 0.5);
    filter: blur(4px);
  }
  100% {
    box-shadow: 0 0 35px 35px rgba(0, 219, 77, 0.8);
    filter: blur(0px);
  }
}

.glowEffect {
  animation: glowPulse 1s ease-in-out;
   transform: scale(1);
   transition: box-shadow 0.5s ease-in-out, transform 0.5s ease-in-out;
}


.card-hover {
 /* box-shadow : "0 2px 5px rgba(0, 0, 0, 0.1)"; */
 /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); */
 transform : translateY(0px)

}

.card-hover:hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
 /* box-shadow : 0 8px 15px rgba(0, 0, 0, 0.2); */
 transform : translateY(-2px) scale(1.02);
}

