.page_header_box {
    padding: 0rem 0.5rem;
    padding-top: 0.25rem;
    user-select: none;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: auto;
    margin: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    min-height: 50px;
    /* min-height: 7%; */
    position: relative;
    overflow: visible;
    overflow-x:clip;
    position: sticky;
    top:0;
    background: white;
    z-index: 100;
  }

.page_header_title_box {
  padding-right: 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.page_header_content_box {
  display: flex;
  flex:1;
  gap: 1rem;
  align-items: center;
}

.page_header_toolkit { 
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 0.5rem;

}

/* .page_header_box:hover{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 5px, rgba(0, 0, 0, 0.05) 0px 5px 5px; ;
  } */
.page_header_box > h2 {font-weight: normal;}



.pageContentWrapper{
  display:flex;
  flex-direction:column;
  height: 100%;
  width:calc(100%);
  /* margin-left:60px */
}

.pageContentWrapperRow{
  display:flex;
  height: 100%;
  width: 100%;
}


.page_header_box > .section {
    margin:0.3rem 0 0.3rem 0;
    padding: 0 1rem;
    display: flex;
    /* flex-wrap: wrap; */
    color: #3a3a3a;
    justify-content: flex-start;
    align-items: center;
    gap:1rem;
    overflow-x:hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    /* min-width: 0; */
    z-index:5;
    background:white
  }

  
.containerLeftMenuAndContent {
  display: flex;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  padding: 0.25rem;
  gap: 0.5rem;

}

.containerMainContent {
  width: 100%;
  flex: 1;
  height: 100%;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.containerMainContent:hover{
  overflow: auto;
}

.containerColumnFlex, .containerColumnFlexNoGap {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.containerColumnFlex:hover{
  overflow: auto;
} 

.containerColumnFlexNoGap:hover {
  overflow: auto;
}

.containerColumnFlex {
  gap: 0.5rem;
}

.containerColumnFlex1 {
  flex:1;
  width:100%;
  /* height: 100%; */
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.containerColumnFlex1:hover{
  overflow: auto;
}


.containerRowFlex {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  box-sizing: border-box;
}

.containerRowFlex1 {
  flex:1;
  overflow: auto;
  box-sizing: border-box;
  position: relative;
}


/* HOME NEW BOX */
/* Parent Container */

.home-new-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-height: 80vh;
  /* background-color: black; */
  color: black;
  padding: 20px;
  box-sizing: border-box;
}

/* Main Content */
.main-content {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
}

.top-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.salutation {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #3a3a3a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.info-button:hover {
  background-color: #555;
}

/* Grid Layout */
.grid-container {
  display: grid;
  grid-template-areas:
      "networth cashflow"
      "portfolio goals"
      "portfolio risk";
  grid-template-rows: 2fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  position: relative;
  height: 70vh;
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  color: white;
  text-align: center;
  min-height: 100px;
  background: #fafafa;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); */
}

/* Central Circle */
.center-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
  height: 150px;
  background-color: white;
  border: none;
  /* color: black; */
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  font-size: 1.2rem;
  font-weight: bold;
  z-index: 10;
}

.score {
  font-size: 2rem;
  font-weight: bold;
}

/* Bottom Section */
.bottom-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.action-button {
  flex: 1;
  padding: 15px;
  margin: 0 5px;
  border: none;
  color: white;
  background-color: #3a3a3a;
  border-radius: 5px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #555;
}

/* Sidebar */
.sidebar-new {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #2a2a2a;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  height: 100%;box-sizing: border-box;
}

.sidebar-new button {
  width: 100%;
  padding: 10px;
  border: none;
  color: white;
  background-color: #3a3a3a;
  border-radius: 5px;
  cursor: pointer;
}

.sidebar-new button:hover {
  background-color: #555;
}
