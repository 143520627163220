.paper> label{
    display: flex;
    gap:0.5rem;
    max-width:100%;
    padding: 0.8rem;
    border: none;
    cursor: pointer;
    white-space:normal;
    text-overflow:clip;
    
    text-align: left;
    &.activeButton {
        background: #f8f8f8;
        color: var(--colorButtonActive);
    };
    
    &.inactiveButton{
    background: transparent;
    border:none;
    }
    &.inactiveButton:hover{
        background: #f8f8f8;
        color:blue;
        border:none;
        }
}
