.inputContainer {
    display: flex;
    border: 1px solid #ddd;
    padding: 0.2rem;
    position: sticky;
    bottom: 0;
    align-items: center;
    background: white;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 5px;
  }
  
  .textInput {
    flex: 1;
    outline: none;
    border: none;
    font-size: 1rem;
    margin-left: 0.5rem;
    min-width: 0;
  }
  
  .fileInput {
    display: none;
  }
  
  .fileLabel {
    display: inline-block;
  }
  
  .imageBox {
    height: 200px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -220px;
    left: 20%;
    box-shadow: var(--boxShadowGeneral);
    padding: 10px;
    background: #f7f7f7;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .imageContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
  