.table {width: 100%;table-layout: fixed;}

.logoTick {display: flex;gap:1rem}

.table th {
    /* background-color: white;
    color: black; */
    border: none;
}
.table td {
  border:none;
  border-bottom:1px solid #ddd ;}

.table td:first-child,
.table th{
  
  white-space: nowrap; /* Prevent wrapping in the first column */
  text-overflow: ellipsis; /* Add ellipsis for content that doesn't fit */
  overflow: hidden;
}
