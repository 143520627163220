.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
    font-weight: bold;
}

/* .content h3 {
    color:gray
} */