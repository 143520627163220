.nav{
    box-sizing: border-box;
    width:calc(100vw - 60px);
    max-width: 100%;
    background: white;
    position:sticky;  
    padding: 0.25rem 0;
    top:0;
    left: 60px;
    z-index: 1000 !important;
  }

  .nav_landing {
    box-sizing: border-box;
    width: 100vw;
    /* height: 50px; */
    min-height: 50px;
    /* max-height: 50px; */
    max-width: 100%;
    /* Use a semi-transparent background to allow the blur to be visible */
    background: transparent;
    padding: 0.5rem;
    position: sticky;
    top: 0;
    z-index: 1000 !important;
    /* Apply the blur effect */
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); 
    /* For Safari */
    /* Optional: Add a border or shadow for better separation */
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }
  
  /* .pageHeader:hover .nav_links > a:not(.active) {
    color: white;
  } */
  
  .nav_wrapper_landing{
    box-sizing: border-box;
    display:flex;
    color:rgb(0, 0, 0);
    gap:0rem;
    padding: 0 0.25rem;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    }

  .nav_wrapper{
  box-sizing: border-box;
  height: 100%;
  max-height: 100%;
  display:flex;
  color:rgb(0, 0, 0);
  gap:0rem;
  padding: 0 0.25rem;
  padding-top: 0.25rem;
  width: 100%;
  align-items: center;
  }
  
  .nav_logo {
    cursor: pointer;
    display: flex;
    color: white;
    position: relative;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 0.25rem;
    padding-right: 1rem;
  }
  
  .search {
    min-width: 15rem;
  }
  
  
  .nav_links{
    flex:1;
    display: flex;
    align-items: center;
    justify-content: safe center;
    margin:0.25rem 1rem 0 1rem;
    height: calc(40px - 0.25rem);
  }

  .nav-link{
    color:gray
  }
  
  .nav-link:hover{
    color:black;
    text-decoration: underline;
  }
  
  .nav_links a.active {
  /* background-color:rgb(255, 255, 255);  */
  font-weight: 800;
  color: black;
  text-decoration: underline;
  background: rgb(255, 255, 255);
  border-radius: 10px 10px 0 0;
  position: relative;
  }
  
  
  .nav_links a.active::before {
    left: -20px;      
  }
  .nav_links a.active::after { 
    right: -20px;
  }
  
  .nav_links a.active:after, .nav_links a.active:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    width: 20px; 
    height: 20px;
    /* Circles are circular */
    -webkit-border-radius: 10px;
    -moz-border-radius:    10px;
    border-radius:         10px;
    background: white;
    /* background:var(--bgColor); */
    background:  var(--bgWoodSmoke);
    /* Circles over squares */
    z-index: 2;
  }
  
  .nav_links_curve_before{
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    outline: none;
    border: none;
    background: white;
    left: -10px;
  }
  
  .nav_links_curve_after{
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    outline: none;
    border: none;
    background: white;
    right: -10px;
  }
  
  
  .nav_links a{
  padding: 0.25rem 0.75rem;
  height: 100%;
  outline: none;
  box-sizing: border-box;
  text-decoration: none;
  /* color:#ddd; */
  color: #d1d1d1;
  margin: 0 0.5rem;
  font-size: 1rem;
  white-space: noWrap;
  position: relative;
  }
  
  /* .nav_links a.active{
    margin: 0 20px;
    } */
  
  
  .nav_links a:not(.active):hover {
    color: rgb(255, 255, 255) !important;
  }
  
  
  .nav_profile {
    margin-left: auto;
    align-self: center;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
