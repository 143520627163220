/* Container that holds the two buttons and the "knob" */
.toggleContainer {
    position: relative;
    display: inline-flex;  /* auto-width based on the button contents */
    align-items: center;
  
    background-color: #f7f7f7;
    border-radius: 25px;
    padding: 4px;
    /* overflow: hidden;  */
    gap: 5px;
    cursor: pointer;
  }
  
  /* The "knob" highlight behind whichever button is active */
  .knob {
    cursor: pointer;
    position: absolute;
    top: 2px;
    bottom: 2px;
    background-color: #000000;
    border-radius: 25px;
  
    /* We'll set left and width from React (knobStyle) */
    transition: left 0.3s ease, width 0.3s ease;
    z-index: 0; /* behind the buttons */
  }
  
  /* ---------- BUTTON SHARED STYLES ---------- */
  .button {
    position: relative;
    z-index: 1; /* above the knob */
    border: none;
    background: transparent;
    font-size: 0.8rem;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    /* Spacing around the text */
    padding: 0.05rem 0.2rem;
  
    outline: none; /* remove focus outline (optional) */
    transition: color 0.2s ease; 
  }
  
  /* ---------- ACTIVE / INACTIVE STATES ---------- */
  
  /* The button currently under the knob: text is "light" */
  .activeButton {
    color: #fff; /* or any "lighter" color you want */
  }
  
  /* The inactive button: gray text, darkens on hover */
  .inactiveButton {
    color: #757575; /* lighter gray text by default */
  }
  
  .inactiveButton:hover {
    color: black; /* gets darker on hover */
  }
  