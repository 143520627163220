.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .pulsating-logo-finite {
    animation: pulse 1.5s ;
  }

  .pulsating-logo {
    animation: pulse 1.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1) rotate(0deg);
      opacity: 1;
    }
    50% {
      transform: scale(0.5) rotate(180deg);
      opacity: 0.8;
    }
    100% {
      transform: scale(1)  rotate(360deg);
      opacity: 1;
    }
  }
  

  /* .pulsating-logo {
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
   */