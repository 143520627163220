.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .overviewContainer {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .overviewContent {
    flex: 1;
  }
  
  .heading {
    font-weight: bold;
  }
  
  .subHeading {
    margin: 0;
  }
  
  /* .textContainer {
    max-width: 700px;
  } */
  
  .menuContainer {
    min-width: 100px;
  }
  
  .reportSection {
    margin-top: 1rem;
  }
  
  .reportContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .sectionHeading {
    font-weight: bold;
    padding: 0.5rem;
  }
  