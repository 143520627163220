/* HomeNewLandingPage.module.css */

/* Container Styles */
.wrapper {
    min-height: 100vh;
    /* margin: 0 auto; */
    padding: 1rem 2rem 4rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
   z-index: 2;
   position: relative;
 
  }

.container {
    /* min-height: 100vh; */
    max-width: 700px;
    /* margin: 0 auto; */
    /* padding: 8rem 2rem; */
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    z-index: 1;
  }
  
  /* Typography */
  .title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  
  .subheading {
    font-size: 1.4rem;
  }
  
  .highlight {
    font-size: 1.4rem;
    margin: 1rem 0;
  }
  
  .intro {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .description {
    font-size: 1.4rem;
    margin: 1rem 0;
  }
  
  /* Section Styles */
  .section {
    margin-bottom: 2rem;
  }
  
  .list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 2rem 0;
  }
  
  .listItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    /* background: #f7f7f7;
    border-radius: 20px;
    padding: 0.5rem;
    max-width: 300px; */
  }
  
  /* Button Styles */
  .buttonContainer {
    margin: 2rem 0;
    display: flex;
    gap: 1rem;
  }
  
  .button {
    padding: 0.75rem 1.5rem !important;
    border: 1px solid #333 !important;
    background: transparent;
    cursor: pointer;
    border-radius: 25px !important;
    color: black !important;
    transition: background 0.3s, color 0.3s;
  }
  
  
  .cursor {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-left: 2px;
    background-color: gray;
    border-radius: 50%;
    animation: blink 1s steps(2, start) infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  /* Cursor Styles */
  /* .cursor {
    display: inline-block;
    margin-left: 2px;
    width: 10px;
    border-radius: 50%;
    animation: blink 1s steps(2, start) infinite;
  } */
  
  /* Animation Keyframes */
  /* @keyframes blink {
    to {
      visibility: hidden;
    }
  } */
  
  .hidden {
    display: none;
  }
  
  .fadeIn {
    animation: fade-in 1.5s forwards;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Typewriter Text Styles */
  .typewriterText {
    white-space: pre-wrap; /* Preserves whitespace and line breaks */
    
  }
  

  