.container{
    height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
   
}

.header {
    width: 100%;
    box-sizing: border-box;
    padding:1rem;
    background-color: black;
    position: fixed;
    top:0

}

.body {
    padding: 2rem;
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.body > h2 {
    text-align: left;
    margin-bottom: 0.5rem;
}
.body > h3 {
    text-align: left;
    margin-bottom: 0.5rem;
}
.body > p{
    text-align: left;
    margin-bottom: 0.5rem;
}