
.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  animation: fadeIn 0.3s ease forwards;
}

.modalContainer {
  background: white;
  padding: 20px;
  border-radius: 10px;
  z-index: 1000000;
  position: relative;
  color: black;
  box-shadow: var(--boxShadowGeneral);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  max-height: 100%;
  animation: fadeIn 0.3s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.7);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


.borderAnim{
  border: 4px solid transparent;
  background: transparent;
  border-color: var(--lightBlue);
  animation: drawBorder 2s infinite,blurbackground 1s linear;
}
@keyframes blurbackground {
  0% {
     background: var(--lightBlue);
  }
  25% {
    background: var(--lightBlue);
      
  }
  50% {
   background: transparent;
}
100% {
  background: transparent;
}
}


  @keyframes drawBorder {
    0% {
        border-top-width: 0;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;
    }
    25% {
        border-top-width: 4px;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;
        
    }
    50% {
        border-top-width: 4px;
        border-right-width: 4px;
        border-bottom-width: 0;
        border-left-width: 0;
    }
    75% {
        border-top-width: 4px;
        border-right-width: 4px;
        border-bottom-width: 4px;
        border-left-width: 0;
    }
    100% {
        border-top-width: 4px;
        border-right-width: 4px;
        border-bottom-width: 4px;
        border-left-width: 4px;
    }
}





.fadePlus{
  background: white;
  /* animation: fadeIn ease 4s infinite; */
  /* -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s; */
}
@keyframes fadeBackground {
  0% {
     background: #f8f8f8;
  }
  25% {
    background: white;
      
  }
  50% {
   background: white;
}
100% {
  background:white;
}}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.fadeBottomUp {
  animation: fadeInOut 1s ease-in-out forwards;
}

@keyframes fadeInOut {
  0%{
    opacity: 0;
    transform: translateX(50px);
  }
  50% {
    opacity: 1;
    transform: translateX(0px);
  }

  100%{
    opacity: 1;
    transform: translateX(100%);
  }
}



.tooltipContainer {
  background: var(--bgWoodSmoke, #333);
  color: #fff;
  border-radius: 4px;
  position: relative;
  max-width: 300px;
  box-sizing: border-box;
  font-size: 14px;
}
