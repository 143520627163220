.news_container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0.25rem;
    justify-content: space-around;
}

.news_content{
    overflow: auto;
    display: flex;
    gap: 2rem;
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 1rem;
    box-sizing: border-box;
    cursor: pointer;
}

.news_content:hover{
    background: #fafafa;
    /* box-shadow: var(--boxShadowGeneral); */
    font-weight: bold;
}