.appLandingContentContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  /* background:white */
}

.appContentContainer{
  box-sizing: border-box;
  flex:1;
  display: flex;
  margin-top:0px;
  margin-right:50px;
  width: calc(100% - 110px);
  min-height: calc(100vh - 0px); 
  height: calc(100vh - 0px);
  margin-left: 60px;
  min-width:calc(100% - 110px);
}
  
  
.appContentContainerNoSidebar{
  box-sizing: border-box;
  flex:1;
  display: flex;
  margin-top:0px;
  margin-right:50px;
  width: calc(100% - 50px);
  min-height: calc(100vh - 0px); 
  height: calc(100vh - 0px);
  margin-left: 0px;
  min-width:calc(100% - 50px);
}
  .appContent{
    /* background: rgba(0,0,0,0.02); */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: calc(100%);
  height: 100%;
  flex-grow: 1;  /*Most Important*/
  flex-basis: 0; /*Most Important*/
  min-width: 0; /*Most Important*/
  overflow-y: auto;
  /* margin-bottom: 2rem; */
  }
  
  
  
  .contentWrapper{
    max-width: 100%;
    width: 100%;
    flex:1;
    position: relative;
    z-index: 1;
    overflow-y: auto;
    /* padding: 0.25rem; */
    box-sizing: border-box;
    /* background: #f4f4f4; */
  }
  
  
  
  .left-sidebar{
    box-sizing: border-box;
    position: fixed;
    left:0;
    top:0px;
    /* top:40px; */
    margin: 0;
    width: 60px;
    /* background:var(--bgWoodSmoke); */
    display: flex;
    flex-direction: column;
    /* border-right:1px solid #ddd; */
    height:calc(100vh - 0px);
    z-index: 100;
    padding: 0.5rem 0;
    box-sizing: border-box;
    /* background:#1F2937;*/
    background: var(--bgWoodSmoke); 
  }
  
  
  .right-sidebar{
      box-sizing: border-box;
      position: fixed;
      right:0;
      top:0px;
      margin: 0;
      width: 50px;
      background: white;
      transition: width 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      border-left:1px solid #eeeeee;
      height:calc(100vh - 0px);
      z-index: 100;
      padding: 0.25rem;
      /* background-color: #fafafa; */
    }
  
  
.sidebar-button {
    margin: 0.3rem 0 !important;
    text-align: center;
    /* width: 100%; */
    box-sizing: border-box;
    padding:0.5rem;
    align-self: center;
    border: none;
    cursor: pointer;
    color: rgb(63, 63, 63) !important;
    border-radius: 5px;
    &.active{
      /* border: 2px solid black; */
     background-color:#eee !important;
     /* color: var(--tabMainBorder); */
     color: #1976d2 !important
    }
    &:not(.active):hover{
      background-color: #F8F8F8;
      color: black !important;
    }
  }
  