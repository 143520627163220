
  .modalWrapper {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  
  .modalHeader {
    margin-bottom: 1rem;
  }
  
  .flexContainer {
    display: flex;
    flex:1;
    gap: 1rem;
    overflow: auto;
  }
  
  .leftPanel {
    width: 30%;
    height: 100%;
    background: #f8f8f8;
    border-radius: 5px;
    padding:0.25rem;
    box-sizing: border-box;
  }
  
  .btn {
    width: 100%;
    border: none;
    border-radius: 0;
    justify-content: left;
    margin-bottom: 0.5rem;
    
    &.activeButton {
        background: black;
        color: white;
    }
  }
  
  .rightPanel {
    flex: 1;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  
  .searchBar {
    border-bottom: 1px solid #ddd;
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;
    display: flex;
    gap: 0.5rem;
    width: 100%;
  }
  

  
  .metricsListContainer {
    flex:1;
    overflow: auto;
    width: 100%;
  }

  .metricList {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f8f8f8;
  }

  .metricListButton {
    border: none;
    flex: 1;
    background: transparent;
    text-align: left;
    padding: 0.5rem !important;
    text-transform: none !important;
    justify-content: flex-start !important;
    color: black !important;
  }
  
  
.activeButton {
    color: var(--selectBlue) !important;
    
  }