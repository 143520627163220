.wlDropDown {
    width: 100%;
    display: flex;
    align-items: center;
    background: none;
    font-size: 1rem;
    border: 1px solid #ddd;
    height: 100%;
  }

/* Styles for the active state (when watchlistMenu is true) */
.active {
  background-color: #eee;
  font-weight: bold;
  
}
/* .wlDropDown:hover{
  background-color: #e3e3e3;
  color:black;
  box-shadow: var(--boxShadowGeneral);

} */