
.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* background: var(--backgroundLinear); */
    border-radius: 0;
    gap: 1rem;
    box-sizing: border-box;
    padding: 0.5rem;
    flex: 1;
  }
  
  .stepHeader {
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .stepTitle {
    font-weight: bold;
  }
  

  .keyPoints, .success, .warning {
    flex: 1;
    border-radius: 5px;
  }

  .keyPoints{
    background: var(--colorInformation);
    min-width: 300px;
  }
  
  .keyPoints.alert, .success.alert, .warning.alert {
    font-size: 1.2rem;
  }
  
  .success {
    width: 30%;
    background: var(--colorSuccess);
    min-width: 300px;
  }
  
  .warning {
    width: 30%;
    background: var(--colorWarning);
    min-width: 300px;
  }
  
  .content {
    width: 100%;
    background: white;
    border-radius: 4px;
    padding: 0.25rem;
    box-sizing: border-box;
  }