


.btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap:0.3rem;
    padding: 10px;
    border-radius: 25px;
    border: 2px solid #eee;
    /* background-color:  var(--bgColor);
    color: white; */
    font-size: 1rem;
    font-weight: bold;
    /* background: transparent; */
  }
  
  /* .btn > * {color: black;} */
  .btn:hover > *{color: white;} 
  .btn:hover{
  background-color:  var(--bgColor);
  color: white;
  }
  

.btnQuestionnaire{
    background: white;
    color:black;
    border:1px solid #ddd !important;
    text-transform: none !important;
    &.activeButton {
      background: green;
      color: white;
    }
    &:hover:not(.activeButton){
      background: #F8F8F8;
      color:black
    }
    &.activeButton:hover{
      background: green;
      color: white;
    }
  }