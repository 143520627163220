.highcharts-data-table tr:hover {
    background: #f1f7ff;
  }
  
.chart_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}
  
table {
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    position: relative;
    overflow: auto;
    /* height: 500px; */
    /* box-shadow: var(--boxShadowGeneral); */
    border: none;
    background: white;
  }
  
  
  table :is(th, td) {
    font-size: 1rem;
    border-collapse: collapse;
    width: 10%;
    padding: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
    border-bottom: 1px solid #e6e6e6;
    text-align: left;
    background: white;
  }
  
  /* Remove border from last row
  tbody > tr:last-of-type > td {
    border-bottom: none;
  }
   */
  table th {
    white-space: balance;
    font-size: 0.8rem;
    z-index: 20;
    /* border-top: 1px solid #ddd; */
    /* border-radius: 10px; */
    position: sticky;
    top: 0px;
    background: white;
    /* background:#F8F8F8; */
  
  }

  tbody tr:hover td {
    background: #fafafa;
  }
  
  /* Remove the old hover styles */
  tbody > tr:hover {
    background: #fafafa;
  }
  
  tbody > td:hover {
    background-color: #fafafa;
  }
  
  
  
  .matt-finish {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #242529; /* Base matte color */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"%3E%3Cfilter id="noiseFilter"%3E%3CfeTurbulence type="fractalNoise" baseFrequency="0.8" numOctaves="20" stitchTiles="stitch"/%3E%3C/filter%3E%3Crect width="100%25" height="100%25" filter="url(%23noiseFilter)"/%3E%3C/svg%3E');
    background-size: 100% 100%; /* Stretch noise to fit the container */
    background-blend-mode: multiply; /* Blend noise with base color */
    color: white;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  /* Add placeholder styling for contenteditable */
  [contenteditable=true]:empty:before {
    content: attr(data-placeholder);
    color: #888;
    font-style: italic;
    cursor: text;
  }

  [contenteditable=true]:focus {
    outline: none;
    cursor: text;
  }
  