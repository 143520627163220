.tooltipContainer {
    height: auto;
    position: absolute;
    background: white;
    z-index: 100000000;
    top: 100%;
    background: white;
    box-shadow: var(--boxShadowGeneral);
    cursor: pointer;
    opacity: 0; /* Start hidden */
  transition: opacity 0.3s ease-in-out; /* Fade in effect */
  }

  .tooltipContainer.show {
    opacity: 1; /* Show the tooltip */
  }

  .triangle {
    position: absolute;
    left: -10px; /* Adjust this value according to your preference */
    top: calc(15px); /* Adjust this value according to your preference */
    width: 0;
    height: 0;
    border-top: 5px solid transparent; /* half of the height */
    border-bottom: 5px solid transparent; /* half of the height */
    border-right: 10px solid white; /* width of the triangle */
    z-index: 10000;
  }

  .triangleTop {
    position: absolute;
    left: calc(50% - 10px); /* Adjust this value according to your preference */
    top: -10px; /* Adjust this value according to your preference */
    width: 0;
    height: 0;
    border-left: 10px solid transparent; /* half of the width */
    border-right: 10px solid transparent; /* half of the width */
    border-bottom: 10px solid white; /* height of the triangle */
    z-index: 10000;
}