.news_image {width: 100px !important;
height: 70px !important;
object-fit: cover !important;
border-radius: 15px !important;}

.video-responsive {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
.video-responsive iframe {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
border-radius: 5px;
object-fit: cover; /* Ensures the video covers the entire iframe */
}