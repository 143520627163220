.container {
    padding: 0.5rem;
    max-width: 100%;
    background: #FFFFFF;
    min-height: 100vh;
}

/* Filter Modal Styles */
.filterContent {
    padding: 1rem;
}

.searchSection {
    margin-bottom: 1.5rem;
}

.searchInput {
    background-color: #f8fafc;
    border-radius: 8px;
}

.searchInput input {
    padding: 12px;
}

.filterSection {
    margin: 1.5rem 0;
}

.filterTitle {
    color: #1e293b;
    font-weight: 600 !important;
    margin-bottom: 1rem !important;
    font-size: 0.85rem !important;
}

.filterDivider {
    margin: 1.5rem 0 !important;
    background-color: #e2e8f0 !important;
}

.filterChips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.filterChip {
    border-radius: 16px !important;
    font-size: 0.875rem !important;
    height: 32px !important;
    transition: all 0.2s ease-in-out !important;
}

.filterChip:hover {
    background-color: #e2e8f0 !important;
}

.selectedChip {
    background-color: #e8f0fe !important;
    color: #1a73e8 !important;
    border: 1px solid #1a73e8 !important;
}

.selectedChip:hover {
    background-color: #d3e3fd !important;
}

/* Prospect Row Styles */
.prospectsRows {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.5rem;
}

.prospectRow {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.prospectRow:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.prospectMainInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.nameSection {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.personIcon {
    color: #64748b;
    font-size: 2rem;
}

.name {
    margin: 0;
    font-size: 1.25rem;
    color: #1e293b;
}

.age {
    color: #64748b;
    font-size: 0.875rem;
}

.riskBadge {
    padding: 6px 12px;
    border-radius: 16px;
    font-size: 0.875rem;
    background-color: #f1f5f9;
    color: #475569;
}

.detailsRow {
    display: flex;
    gap: 2rem;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
}

.detail {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
}

.detail svg {
    color: #64748b;
    font-size: 1.25rem;
}

.detailLabel {
    display: block;
    font-size: 0.75rem;
    color: #64748b;
    margin-bottom: 0.25rem;
}

.detailValue {
    display: block;
    font-size: 0.875rem;
    color: #1e293b;
    font-weight: 500;
}

.interestsSection {
    margin-bottom: 1.5rem;
}

.interestsLabel {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #64748b;
    margin-bottom: 0.75rem;
}

.serviceIcon {
    font-size: 1.25rem !important;
    color: #64748b;
}

.interestTags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.interestTag {
    padding: 4px 12px;
    background-color: #f8fafc;
    border-radius: 16px;
    font-size: 0.75rem;
    color: #475569;
}

.actionButtons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.actionButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.buttonIcon {
    font-size: 0.8rem !important;
}

.viewButton {
    background-color: #f8fafc;
    color: #1e293b;
    font-size: 0.9rem;
}

.viewButton:hover {
    background-color: #f1f5f9;
    border-color: #cbd5e1;
}

.connectButton {
    background-color: #067df3;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
}

.connectButton:hover {
    background-color: #1557b0;
}

/* Update media query for better mobile responsiveness */
@media (max-width: 768px) {
    .container {
        padding: 1rem;
    }

    .detailsRow {
        gap: 1rem;
    }

    .detail {
        min-width: 150px;
    }

    .actionButtons {
        flex-direction: column;
        width: 100%;
    }

    .actionButton {
        width: 100%;
        justify-content: center;
    }
} 


.metricsContainer {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
  
  .metricCard {
    flex: 1;
    min-width: 220px;
    background: #FFFFFF;
    padding: 1rem 1.5rem;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    transition: all 0.2s ease-in-out;
  }
  
  .metricCard:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
  
  .metricValue {
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    color: #2D3748 !important;
    margin-bottom: 0.25rem !important;
    letter-spacing: -0.5px !important;
  }
  
  .metricTitle {
    font-size: 0.875rem !important;
    color: #4A5568 !important;
    margin-bottom: 0.25rem !important;
  }
  
  .metricSubtitle {
    font-size: 0.75rem !important;
    color: #718096 !important;
    display: block;
  }
  