
.scoreWrapper{
    /* border: 2px solid #F8F8F8; */
    background:#fafafa;
    border-radius: 5px;
    text-align: left;
    font-size: 1rem;
    padding: 1rem 0.5rem;
    /* max-height: 80px; */
    /* border-right: 1px solid #ddd;
    border-left: 1px solid #ddd; */
    max-width: 100%;
    margin-bottom: 0.25rem;
    /* overflow-x: auto; */
   
  }

.scoreWrapper > h4 {
    margin: 0;
}

.subScore {
    position: sticky;
    margin-top: 0.2rem;
    top: 0;
    display: flex;
    gap:0.5rem;
    max-width: 100%;
    white-space: nowrap;
    align-items: center;
    text-overflow: ellipsis;
    flex-wrap: wrap;
    /* overflow-x: auto; */
} 


.subScore > h5 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;}