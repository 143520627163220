.tableContainer {
  flex:1;
  overflow-x: hidden;
  height:100%;
  overflow-y: auto;
  cursor: pointer;
}

.table {
  table-layout: fixed;
  position: relative;
  width: 100%;
  max-width: 100%;
  border: none;
  border-spacing: 0;
  border-collapse: collapse;
  /* overflow-x: hidden; */
  overflow: visible;
  height: auto;
 
}


.table :is(th,td) {
    border: none;
    white-space: nowrap;
    text-align: right;
    padding: 5px 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: black;
} 

.table th {
  border-top: 0 solid black;
  position: sticky;
  top: 0;
  background: #f8f8f8;
  color: black;
  z-index: 20;
  text-align: left;
}

.table td {
  position: relative;
  text-align: left;
}

.table tbody > tr {
  position: relative; /* Ensure the tr has relative positioning */
  cursor: pointer;
}
  

.table th > .resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}

.resizer:hover, .resizing {
    border-right: 2px solid blue;
}


.table tbody > tr:hover .closeIcon 
{ 
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
.closeIcon {
    display: none !important;
    position: absolute;
    z-index: 5;
    right:0%;
    top: 50%;
    transform: translateY(-50%);
    background: rgb(225, 223, 223);
} 

.selectedRow {
  border: 2px solid blue !important;
  position: relative;
}



