h1,h2,h3,h4,h5,h6{
    margin:0;
    color: var(--bgColor);
    
  }
  
  h4,h5,h6{ 
    font-weight:400
  }
  
  h1 {
    font-size: 2rem;
    font-weight:600
  }
  
  h2{
    font-size: 1.6rem;
    font-weight:600
  }
  
  h3{
    font-size: 1.3rem;
    font-weight:600
  }
  
  h4{
    font-size: 1rem;
  }
  
  h5{
    font-size: 0.8rem;
  }
  
  a{
    text-decoration: none;
    color: gray;
  }
  a:hover{
    color:var(--selectBlue);
  }

  
.font_menu {
    font-size: 1.6rem;
}

.font_title {
    font-size: 1.6rem;
}

.font_chart_title {
    font-size: 1.2rem;
    color: gray;
    font-weight: bold;
}